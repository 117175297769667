<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <div class="filter-box">
      <div class="row">
        <span>创建时间</span>
        <el-date-picker
          v-model="registerTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          style="position: relative; top: 1px"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <span>投放时间</span>
        <el-date-picker
          v-model="selectTime"
          style="width: 330px"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>

        <select-dept-user :params="params"></select-dept-user>
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>

      <div>
        <div class="custom-card">
          <div class="data-label">总花费：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{ statistic.cost || 0 }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">曝光量：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.viewCount || 0
            }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">点击量：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.validClickCount || 0
            }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">点击率：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.ctrStr || 0
            }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">转化目标量：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.validClickCount || 0
            }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">转化目标成本：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.conversionsCost || 0
            }}</span>
          </div>
        </div>
      </div>
      <div class="custom-table">
        <el-table
          v-loading="loading"
          height="54vh"
          @sort-change="handleSortChange"
          style="width: 100%"
          :data="dataList"
        >
          <el-table-column label="创建时间" fixed="left" min-width="120">
            <template slot-scope="scope">
              <span>{{
                scope.row.createDate.length > 0 ? scope.row.createDate : "汇总"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="美工" prop="adminUserName" fixed="left">
          </el-table-column>
          <el-table-column
            label="素材ID"
            prop="artId"
            fixed="left"
          ></el-table-column>
          <el-table-column label="内容" min-width="180">
            <template slot-scope="scope">
              <div @click.capture="checkDetail(scope.row)">
                <video
                  v-if="scope.row.artType === 'video'"
                  width="60"
                  height="36"
                  :src="scope.row.showValue"
                ></video>
                <el-button plain v-else-if="scope.row.artType === 'audio'"
                  >音视频</el-button
                >
                <img
                  v-else-if="scope.row.artType === 'pic'"
                  width="60"
                  height="36"
                  :src="scope.row.showValue"
                />
                <span
                  v-else-if="scope.row.artType === 'text'"
                  style="width: 60px; height: 36px"
                  :src="scope.row.showValue"
                ></span>
                <span v-else> --</span>
              </div>
              <!-- </el-popover> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            sortable="custom"
            label="花费(元)"
            width="100"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="viewCount"
            label="曝光量"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="validClickCount"
            sortable="custom"
            label="点击量"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="ctr"
            sortable="custom"
            label="点击率"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.ctrStr }}
            </template>
          </el-table-column>
          <el-table-column
            prop="conversionsCount"
            sortable="custom"
            label="目标转化量"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="conversionsCost"
            sortable="custom"
            label="目标转化成本(元)"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="conversionsRate"
            sortable="custom"
            label="目标转化率"
            min-width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.conversionsRateStr }}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderRoi"
            sortable="custom"
            label="下单ROI"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="orderAmount"
            sortable="custom"
            label="下单金额"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="orderUnitPrice"
            sortable="custom"
            label="下单客单价"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="thousandDisplayPrice"
            sortable="custom"
            label="千次曝光成本"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="dailyBudget"
            sortable="custom"
            label="日预算"
            min-width="120"
          >
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        style="margin: 10px 0; text-align: right"
        background
        @current-change="handlePageChange(val)"
        :current-page="page"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <template v-if="info">
      <designer-detail
        :showDialog.sync="showDialog"
        :info="info"
      ></designer-detail>
    </template>
  </div>
</template>

<script>
import { getArtDelivery } from "@/api/novelPut";
import ExcludeQuery from "@/components/excludeQuery";
import SelectDeptUser from "@/components/SelectDeptUser";
import SelectField from "@/components/SelectField";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
import { getClassifyList } from "@/api/classify";
import { yesterdayOptions, pickerOptions } from "@/assets/js/options";
import { mapGetters, mapActions } from "vuex";
import designerDetail from "./designer-detail";
import DesignerDetail from "./designer-detail.vue";
import {getRecentlyWeek} from '@/assets/js/utils.js'
export default {
  data() {
    return {
      dataList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      params: {},
      statistic: {},
      bookCategoryList: [],
      resourceCategoryList: [],
      loading: false,
      pickerOptions,
      selectTime: [],
      registerTime: [],
      showDialog: false,
      info: null,
      sortName: null,
      sortDesc: null,
    };
  },
  components: {
    ExcludeQuery,
    SelectDeptUser,
    SelectPlatformChannel,
    SelectField,
    designerDetail,
  },
  computed: {
    ...mapGetters(["putUserList"]),
  },
  watch: {
    selectTime: {
      handler(newV) {
        this.params.selectTime = newV;
      },
    },
    registerTime: {
      handler(newV) {
        this.params.registerTime = newV;
      },
    },
  },
  methods: {
    handlePageChange(val = null) {
      if (val) {
        this.page = val;
      }
      this.loading = true;
      this.addRouterHistory({
        path: this.$route.path,
        params: {
          selectTime: this.params.selectTime,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
          registerTime: this.params.registerTime,
        },
      });
      let tmpParams = {
        page: this.page,
        pageSize: this.pageSize,
        beginDate: this.params.selectTime[0],
        endDate: this.params.selectTime[1],
        adminUserId: this.params.adminUserId,
        deptId: this.params.deptId,
        registerBeginTime: this.params.registerTime ? this.params.registerTime[0]:null,
        registerEndTime: this.params.registerTime? this.params.registerTime[1] : null,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
      };
      getArtDelivery(tmpParams)
        .then((res) => {
          this.total = res.list.total;

          this.statistic = JSON.parse(JSON.stringify(res.total));
          res.list.list.forEach((item) => {
            let typeInfo = this.getArtType(item);
            item.artType = typeInfo.artType;
            item.cname = typeInfo.cname;
            item.showValue = typeInfo.value;
            item.realType = typeInfo.realType;
          });
          res.list.list.unshift({
            createDate: "汇总",
            adminUserName: "--",
            artType: "--",
            conversionsCost: res.total.conversionsCost,
            conversionsCount: res.total.conversionsCount,
            conversionsRate: res.total.conversionsRate,
            conversionsRateStr: res.total.conversionsRateStr,
            cost: res.total.cost,
            ctr: res.total.ctr,
            ctrStr: res.total.ctrStr,
            dailyBudget: res.total.dailyBudget,
            orderAmount: res.total.orderAmount,
            orderRoi: res.total.orderRoi,
            orderUnitPrice: res.total.orderUnitPrice,
            thousandDisplayPrice: res.total.thousandDisplayPrice,
            validClickCount: res.total.validClickCount,
            viewCount: res.total.viewCount,
          });
          this.dataList = res.list.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getArtType(row) {
      let type;
      let realType;
      let cname;
      let artList;
      let audioOrVideo = false;
      // 如果有多重素材 则为混合素材
      let isMixed =
        ["artMedia" in row, "artImg" in row, "artContent" in row].filter(
          (item) => !!item
        ).length > 1
          ? true
          : false;

      if ("artMedia" in row && !"artImg" in row && !"artContent" in row) {
        realType = "视频/音频";
      }
      if ("artContent" in row && !"artImg" in row && !"artMedia" in row) {
        realType = "文本";
      }
      if ("artImg" in row && !"artContent" in row && !"artMedia" in row) {
        realType = "图片";
      }

      if ("artMedia" in row) {
        artList = row.artMedia.split(",");
        audioOrVideo = true;
      } else if ("artImg" in row) {
        artList = row.artImg.split(",");
        audioOrVideo = true;
      } else if ("artContent" in row) {
        artList = row.artContent.split(",");
      }
      if (audioOrVideo) {
        let itemType = artList[0].match(/.(\w+)$/g)[0].slice(1);
        if (["mp4", "mov", "avi"].includes(itemType)) {
          type = "video";
          cname = "视频";
        }
        if (["png", "jpeg", "jpg", "bmp"].includes(itemType)) {
          type = "pic";
          cname = "图片";
        }
        if (["mp3", "m4a", "mpeg"].includes(itemType)) {
          type = "audio";
          cname = "音频";
        }
      } else {
        type = "text";
        cname = "文本";
      }
      return {
        artType: type,
        realType: isMixed ? "混合" : realType,
        cname,
        value: artList[0],
      };
    },
    getMediaType(item) {
      let reg = /.(\w+)$/g;
      let itemType = item.match(reg)[0].slice(1);

      if (["mp4", "mov", "avi"].includes(itemType)) {
        return "video";
      }
      if (["mp3", "m4a"].includes(itemType)) {
        return "audio";
      }
    },
    // 获取书籍、素材库分类
    getCategory() {
      getClassifyList({
        type: 3,
      }).then((res) => {
        this.bookCategoryList = res;
      });
      getClassifyList({ type: 5 }).then((res) => {
        this.resourceCategoryList = res;
      });
    },
    checkDetail(row) {
      this.info = row;
      this.showDialog = true;
    },
    handleSortChange({ prop, order }) {
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.handlePageChange();
    },
    // 重置按钮
    handleReset() {
      let nowTime = new Date()
        .toLocaleDateString()
        .split("/")
        .map((item) => item.padStart(2, "0"))
        .join("-");
      this.params.adminUserId = null;
      this.params.selectTime = getRecentlyWeek(false)
      this.params.registerTime = null;
      this.params.deptId = null;
      this.page = 1;
      this.pageSize = 15;
      this.selectTime = JSON.parse(JSON.stringify(this.params.selectTime));
      this.registerTime = null
      this.handlePageChange();
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
  },
  components: {
    SelectDeptUser,
    DesignerDetail,
  },
  handleClose() {
    this.showDialog = false;
  },
  created() {
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
   
      let nowTime = new Date()
        .toLocaleDateString()
        .split("/")
        .map((item) => item.padStart(2, "0"))
        .join("-");
       
      this.params.selectTime = getRecentlyWeek(false);
    this.selectTime = JSON.parse(JSON.stringify(this.params.selectTime));
    this.handlePageChange();
    this.getCategory();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-table {
  .is-leaf {
    border-bottom: 0px;
    height: 56px !important;
  }
}

video {
  width: 60px;
  height: 36px;
}
audio{
  width:170px;
  height: 36px;
}
.row {
  padding-bottom: 20px;
}
.custom-card {
  margin-bottom: 20px;
}
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
  width: calc(100vw - 300px);
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
.report-cont {
  width: 500px;
  height: 690px;
  margin-right: 10px;
  border: 1px solid #ebeef5;
  border-radius: 6px;
  background-color: #fff;
  padding: 10px;
}
.book-data {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}
.data-label {
  color: #475669 !important;
  font-weight: 400 !important;
}
.art-item-detail {
  box-sizing: border-box;
  padding: 10px 0;
  height: auto;
  max-height: 400px;
  overflow: auto;

  h3 {
    text-align: left;
  }
  .art-content {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .art-img {
    width: 100%;
  }
  .img-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .img-item {
      width: 170px;
      height: 150px;
      object-fit: fill;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  .art-media {
    .art-item {
      margin-bottom: 10px;
    }
    video {
      height: 180px;
      width: 100%;
      object-fit: fill;
    }
    audio {
      width: 100%;
      height:50px;
    }
  }
}
</style>
