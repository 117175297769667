<template>
  <el-dialog
    title="内容详情"
    :visible.sync="showDialog"
    width='820px'
    append-to-body
    :before-close="handleClose"
  >
    <div class="art-item-detail">
      <template v-if="'artImg' in info">
        <div class="art-img">
          <div
            v-for="n in Math.ceil(info.artImg.split(',').length / 3)"
            :key="n"
          >
            <div v-if="'artContent' in info" class="art-content">
              文案：{{ info.artContent }}
            </div>
            <div class="part-list">
              <img
                class="img-item"
                v-for="(item, index) in info.artImg
                  .split(',')
                  .slice(3 * (n - 1), 3 * n)"
                :key="index"
                :src="item"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-if="'artMedia' in info">
        <div class="art-media">
          <div
            v-for="n in Math.ceil(info.artMedia.split(',').length / 3)"
            :key="n"
          >
            <div v-if="'artContent' in info" class="art-content">
              文案：{{ info.artContent }}
            </div>
            <div class="part-list">
              <div
                v-for="(item, index) in info.artMedia
                  .split(',')
                  .slice(3 * (n - 1), 3 * n)"
                :key="index"
              >
                <div class="part-item">
                  <video
                    v-if="getMediaType(item) === 'video'"
                    controls
                    :src="item"
                  ></video>
                  <audio
                    v-if="getMediaType(item) === 'audio'"
                    controls
                    :src="item"
                  ></audio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "DesignerDetail",
  props: ["showDialog", "info"],
  data() {
    return {};
  },
  watch: {
    showDialog: {
      handler(newV) {
        if (newV) {
          this.initData();
        }
      }
    }
  },
  methods: {
    initData() {},
    getMediaType(item) {
      let reg = /.(\w+)$/g;
      let itemType = item.match(reg)[0].slice(1);

      if (["mp4", "mov", "avi"].includes(itemType)) {
        return "video";
      }
      if (["mp3", "m4a",'mpeg'].includes(itemType)) {
        return "audio";
      }
    },
    handleClose() {
      this.$emit("update:showDialog", false);
    }
  }
};
</script>
<style lang="scss" scoped>
.art-item-detail {
  box-sizing: border-box;
  padding: 10px 0;
  height: auto;
  overflow: auto;

  h3 {
    text-align: left;
  }
  .art-content {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .art-img {
    width: 100%;
  }
  .part-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    .img-item {
      width: 250px;
      height: 150px;
      object-fit: fill;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .part-item {
      margin-right:10px;
      margin-bottom:10px;
      video {
        height: 150px;
        width: 250px;
        object-fit: fill;
      }
      audio {
        width:250px;
        height:50px;
      }
    }
  }
  .art-media {
    .art-item {
      margin-bottom: 10px;
    }
  }
}
</style>
